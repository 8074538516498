<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-tabs class="main-tabs" type="card">
        <a-tab-pane key="content" class="main-tabs" type="card">
          <span slot="tab"> <a-icon type="project" />Содержимое </span>
          <!-- lang tabs -->
          <a-row align="middle" class="form_title sm-mb">
            <a-col :span="4" class="form_title"> Язык: </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
              </a-row>
            </a-col>
          </a-row>

          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title"> Название: </a-col>
            <a-col :span="15" class="sm-mb">
              <a-input
                v-model="form['title_' + getKey]"
                name="linkName"
                class="form_title-input"
                placeholder="Введите название"
              />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title"> Должность: </a-col>
            <a-col :span="15" class="sm-mb">
              <a-input
                v-model="form['position_' + getKey]"
                name="linkName"
                class="form_title-input"
                placeholder="Введите должность"
              />
            </a-col>
          </a-row>
          <a-row class="sm-mb">
            <a-col :span="4" class="form_title"> Дата публикации: </a-col>
            <a-col :span="12">
              <input v-model="form.date" type="date" />
            </a-col>
          </a-row>
          <a-row class="sm-mb">
            <a-col :span="4" class="form_title">
              Выберите департамент <br />
              (Министертсва)
            </a-col>
            <a-col :span="12">
              <a-select
                v-model="form.activity"
                :disabled="
                  (activityList &&
                    activityList.data &&
                    activityList.data.length === 0) ||
                  !!isDepart
                "
                :filter-option="filterOption"
                option-filter-prop="children"
                placeholder="Выберите департамент(Министертсва)"
                show-search
                style="width: 100%"
              >
                <a-select-option
                  v-for="act in activityList.data"
                  :key="act.id"
                  :value="act.id"
                >
                  {{ act.title || "-" }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
          <a-row class="sm-mb">
            <a-col :span="4" class="form_title"> Выберите департамент </a-col>
            <a-col :span="12">
              <a-select
                v-model="form.departament"
                :disabled="
                  (departmentList &&
                    departmentList.data &&
                    departmentList.data.length === 0) ||
                  !!isActivity
                "
                :filter-option="filterOption"
                option-filter-prop="children"
                placeholder="Выберите департамент"
                show-search
                style="width: 100%"
              >
                <a-select-option
                  v-for="depart in departmentList.data"
                  :key="depart.id"
                  :value="depart.id"
                >
                  {{ depart.title || "-" }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
          <a-row v-if="langTab === 'oz'" class="sm-mb">
            <a-col :span="4" class="form_title">Файлы документы:</a-col>
            <a-col :span="12">
              <a-button
                v-if="docs_oz.length === 0"
                type="primary"
                @click="$refs.documentRef.click()"
              >
                <input
                  ref="documentRef"
                  accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                  hidden
                  type="file"
                  multiple
                  @change="handleDocumentInput($event, 'docs_oz')"
                />
                <a-icon type="upload" />Выберите файл
              </a-button>

              <template v-if="docs_oz.length !== 0">
                <p style="padding-left: 15px">Файлы для загрузки</p>
                <InputFileItem
                  v-for="item in docs_oz"
                  :key="item.uid"
                  :file="item.binary_file"
                  :inputcopy="false"
                  class="mt-2 no-a-hover"
                  @delete="deleteDocumentFileFromState(item, 'docs_oz', 'oz')"
                />

                <a-row
                  style="border-bottom: 1px solid #ddd; padding-bottom: 15px"
                />
              </template>
            </a-col>
          </a-row>
          <a-row v-if="langTab === 'uz'" class="sm-mb">
            <a-col :span="4" class="form_title">Файлы документы:</a-col>
            <a-col :span="12">
              <a-button
                v-if="docs_uz.length === 0"
                type="primary"
                @click="$refs.documentRef.click()"
              >
                <input
                  ref="documentRef"
                  accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                  hidden
                  type="file"
                  multiple
                  @change="handleDocumentInput($event, 'docs_uz')"
                />
                <a-icon type="upload" />Выберите файл
              </a-button>

              <template v-if="docs_uz.length !== 0">
                <p style="padding-left: 15px">Файлы для загрузки</p>
                <InputFileItem
                  v-for="item in docs_uz"
                  :key="item.uid"
                  :file="item.binary_file"
                  :inputcopy="false"
                  class="mt-2 no-a-hover"
                  @delete="deleteDocumentFileFromState(item, 'docs_uz', 'uz')"
                />

                <a-row
                  style="border-bottom: 1px solid #ddd; padding-bottom: 15px"
                />
              </template>
            </a-col>
          </a-row>
          <a-row v-if="langTab === 'ru'" class="sm-mb">
            <a-col :span="4" class="form_title">Файлы документы:</a-col>
            <a-col :span="12">
              <a-button
                v-if="docs_ru.length === 0"
                type="primary"
                @click="$refs.documentRef.click()"
              >
                <input
                  ref="documentRef"
                  accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                  hidden
                  type="file"
                  multiple
                  @change="handleDocumentInput($event, 'docs_ru')"
                />
                <a-icon type="upload" />Выберите файл
              </a-button>

              <template v-if="docs_ru.length !== 0">
                <p style="padding-left: 15px">Файлы для загрузки</p>
                <InputFileItem
                  v-for="item in docs_uz"
                  :key="item.uid"
                  :file="item.binary_file"
                  :inputcopy="false"
                  class="mt-2 no-a-hover"
                  @delete="deleteDocumentFileFromState(item, 'docs_ru', 'ru')"
                />

                <a-row
                  style="border-bottom: 1px solid #ddd; padding-bottom: 15px"
                />
              </template>
            </a-col>
          </a-row>
          <a-row v-if="langTab === 'qr'" class="sm-mb">
            <a-col :span="4" class="form_title">Файлы документы:</a-col>
            <a-col :span="12">
              <a-button
                v-if="docs_qr.length === 0"
                type="primary"
                @click="$refs.documentRef.click()"
              >
                <input
                  ref="documentRef"
                  accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                  hidden
                  type="file"
                  multiple
                  @change="handleDocumentInput($event, 'docs_qr')"
                />
                <a-icon type="upload" />Выберите файл
              </a-button>

              <template v-if="docs_qr.length !== 0">
                <p style="padding-left: 15px">Файлы для загрузки</p>
                <InputFileItem
                  v-for="item in docs_qr"
                  :key="item.uid"
                  :file="item.binary_file"
                  :inputcopy="false"
                  class="mt-2 no-a-hover"
                  @delete="deleteDocumentFileFromState(item, 'docs_qr', 'qr')"
                />

                <a-row
                  style="border-bottom: 1px solid #ddd; padding-bottom: 15px"
                />
              </template>
            </a-col>
          </a-row>
          <a-row v-if="langTab === 'en'" class="sm-mb">
            <a-col :span="4" class="form_title">Файлы документы:</a-col>
            <a-col :span="12">
              <a-button
                v-if="docs_en.length === 0"
                type="primary"
                @click="$refs.documentRef.click()"
              >
                <input
                  ref="documentRef"
                  accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                  hidden
                  type="file"
                  multiple
                  @change="handleDocumentInput($event, 'docs_en')"
                />
                <a-icon type="upload" />Выберите файл
              </a-button>

              <template v-if="docs_en.length !== 0">
                <p style="padding-left: 15px">Файлы для загрузки</p>
                <InputFileItem
                  v-for="item in docs_en"
                  :key="item.uid"
                  :file="item.binary_file"
                  :inputcopy="false"
                  class="mt-2 no-a-hover"
                  @delete="deleteDocumentFileFromState(item, 'docs_en', 'en')"
                />

                <a-row
                  style="border-bottom: 1px solid #ddd; padding-bottom: 15px"
                />
              </template>
            </a-col>
          </a-row>
          <!-- active toggle -->
          <a-row style="margin-bottom: 25px">
            <a-col :span="4" class="form_title"> Статус: </a-col>
            <a-switch v-model="form.is_active" />
          </a-row>
          <a-row style="margin-top: 20px">
            <a-col :span="4" />
            <a-col :span="12">
              <a-row>
                <a-button style="margin-right: 10px" @click="$router.go(-1)">
                  <a-icon type="close" />Отмена
                </a-button>
                <a-button
                  style="margin-left: 10px"
                  type="primary"
                  @click="
                    submit(form.id ? (saveback = false) : (saveback = true))
                  "
                >
                  <a-icon type="check" />
                  {{ form.id ? $t("Save") : $t("Add") }}
                </a-button>
                <a-button
                  v-if="form.id"
                  style="margin-left: 10px"
                  type="primary"
                  @click="submit((saveback = true))"
                >
                  <a-icon type="check" />
                  {{ $t("SaveAndExit") }}
                </a-button>
              </a-row>
            </a-col>
          </a-row>
        </a-tab-pane>
        <!-- <a-tab-pane key="documents">
          <span slot="tab"> <a-icon type="project" />Документы </span>
        </a-tab-pane> -->
      </a-tabs>
    </a-col>
  </a-row>
</template>

<script>
import InputFileItem from "@/components/utils/input-file-item"
export default {
  components: { InputFileItem },
  data() {
    return {
      form: {
        title_uz: "",
        title_ru: "",
        title_en: "",
        title_oz: "",
        title_qr: "",
        position_uz: "",
        position_ru: "",
        position_en: "",
        position_oz: "",
        position_qr: "",
        file_uz: null,
        file_ru: null,
        file_en: null,
        file_oz: null,
        file_qr: null
      },
      fileList: [],
      langTab: "oz",
      docs_uz: [],
      docs_ru: [],
      docs_en: [],
      docs_oz: [],
      docs_qr: [],
      departmentList: [],
      activityList: []
    }
  },
  computed: {
    getKey() {
      return this.langTab
    },
    isActivity() {
      // console.log(this.form.activity)
      return this.form.activity
    },
    isDepart() {
      // console.log(this.form.departament)
      return this.form.departament
    }
  },
  async mounted() {
    await this.fetchData()
    try {
      this.departmentList = await this.$api.get(
        "/admin/vacancy/departament/list/"
      )
      this.activityList = await this.$api.get("/admin/common/activity/list/")
    } catch (error) {
      console.error(error)
      return error
    }
  },
  methods: {
    newsDocs() {
      return this.form && this.form.filter((item) => item.file)
    },
    async deleteDocumentFileFromState(e, el, lang) {
      this[el] = this[el].filter((file) => {
        return file.uid !== e.uid
      })
      if (this.form && this.form.id && this.form[`file_${lang}`]) {
        const id = this.form.id
        await this.$api.patch(
          `/admin/budget/activity-information-attachment/${id}/delete/file/?file=file_${lang}`
        )
      }
      await this.fetchData()
    },
    handleDocumentInput(e, el) {
      this.file_List = e.target.files
      if (e.target.files && e.target.files !== 0) {
        Array.from(e.target.files).forEach((item) => {
          this[el] = [
            ...this[el],
            {
              binary_file: item,
              uid: Math.random().toString().slice(2)
            }
          ]
        })
      }
      this.$refs.documentRef.value = null
    },
    async fetchData() {
      if (!this.$route.params.id) return
      let id = this.$route.params.id
      const { data } = await this.$api.get(`/admin/vacancy/${id}/detail/`)
      this.form = data
      if (this.form && this.form.file_oz) {
        this.docs_oz = [
          {
            binary_file: {
              name: "",
              file: ""
            }
          }
        ]
        this.docs_oz[0].binary_file.name =
          this.form.file_oz.split("/")[this.form.file_oz.split("/").length - 1]
        this.docs_oz[0].binary_file.file = this.form.file_oz
      }
      if (this.form && this.form.file_uz) {
        this.docs_uz = [
          {
            binary_file: {
              name: "",
              file: ""
            }
          }
        ]
        this.docs_uz[0].binary_file.name =
          this.form.file_uz.split("/")[this.form.file_uz.split("/").length - 1]
        this.docs_uz[0].binary_file.file = this.form.file_uz
      }
      if (this.form && this.form.file_ru) {
        this.docs_ru = [
          {
            binary_file: {
              name: "",
              file: ""
            }
          }
        ]
        this.docs_ru[0].binary_file.name =
          this.form.file_ru.split("/")[this.form.file_ru.split("/").length - 1]
        this.docs_ru[0].binary_file.file = this.form.file_ru
      }
      if (this.form && this.form.file_en) {
        this.docs_en = [
          {
            binary_file: {
              name: "",
              file: ""
            }
          }
        ]
        this.docs_en[0].binary_file.name =
          this.form.file_en.split("/")[this.form.file_en.split("/").length - 1]
        this.docs_en[0].binary_file.file = this.form.file_en
      }
      if (this.form && this.form.file_qr) {
        this.docs_qr = [
          {
            binary_file: {
              name: "",
              file: ""
            }
          }
        ]
        this.docs_qr[0].binary_file.name =
          this.form.file_qr.split("/")[this.form.file_qr.split("/").length - 1]
        this.docs_qr[0].binary_file.file = this.form.file_qr
      }
    },
    async submit(saveback) {
      let f = new FormData()
      Object.keys(this.form).forEach((key) => {
        key !== "file_qr" &&
          key !== "file_ru" &&
          key !== "file_en" &&
          key !== "file_oz" &&
          key !== "file_uz" &&
          this.form[key] &&
          f.append(key, this.form[key])
      })
      const createOrUpdate = {
        url:
          this.form && this.form.id
            ? "/admin/vacancy/" + this.form.id + "/update/"
            : "/admin/vacancy/create/",
        method: this.form && this.form.id ? "patch" : "post"
      }
      if (this.docs_uz && this.docs_uz[0] && this.docs_uz[0].uid) {
        this.docs_uz.forEach((item) => {
          item &&
            item.binary_file &&
            !item.binary_file.id &&
            f.append("file_uz", item.binary_file, item.binary_file.name)
        })
      }
      if (this.docs_oz && this.docs_oz[0] && this.docs_oz[0].uid) {
        this.docs_oz.forEach((item) => {
          item &&
            item.binary_file &&
            !item.binary_file.id &&
            f.append("file_oz", item.binary_file, item.binary_file.name)
        })
      }
      if (this.docs_ru && this.docs_ru[0] && this.docs_ru[0].uid) {
        this.docs_ru.forEach((item) => {
          item &&
            item.binary_file &&
            !item.binary_file.id &&
            f.append("file_ru", item.binary_file, item.binary_file.name)
        })
      }
      if (this.docs_en && this.docs_en[0] && this.docs_en[0].uid) {
        this.docs_en.forEach((item) => {
          item &&
            item.binary_file &&
            !item.binary_file.id &&
            f.append("file_en", item.binary_file, item.binary_file.name)
        })
      }
      if (this.docs_qr && this.docs_qr[0] && this.docs_qr[0].uid) {
        this.docs_qr.forEach((item) => {
          item &&
            item.binary_file &&
            !item.binary_file.id &&
            f.append("file_qr", item.binary_file, item.binary_file.name)
        })
      }
      try {
        let res = await this.$api[createOrUpdate.method](createOrUpdate.url, f)
        if ((res && res.status === 201) || res.status === 200) {
          this.$message.success(
            `Успешно ${this.form.id ? "обновлена" : "создана"}!`
          )
        }
        if (saveback) {
          this.$router.push({ name: "vacancy" })
        }
      } catch (e) {
        this.$message.error(
          `Ошибка при ${this.form.id ? "обновлении" : "создании"}!`
        )
        console.error(e)
      }
    }
  }
}
</script>

<style>
.committee-image {
  overflow: hidden;
  margin-top: 15px;
}
.committee-image > img {
  height: 200px;
}
.isInvalidUrl {
  border: 1px solid red;
  box-shadow: 0 0 3px red;
}
.error-message {
  color: red;
  font-size: 12px;
}
</style>
